// Gazette Api
const gazette = '/gazette/'
export const gazetteListApi = gazette + 'list'
export const gazetteDetailsApi = gazette + 'details'
export const gazetteRequestListApi = gazette + 'request-list'
export const gazetteApproveListApi = gazette + 'approve-list'
export const gazettePublishedListApi = gazette + 'published-list'
export const gazetteStoreApi = gazette + 'store'
export const gazetteUpdateApi = gazette + 'update'
export const gazetteToggleApi = gazette + 'toggle-status'
export const gazetteForwardStoreApi = gazette + 'forward-store'
export const gazetteApproveApi = gazette + 'approve-store'
export const gazettePublisedListApi = gazette + 'published-store'
export const userListByDesignationApi = 'user/users-by-designation-id'
